<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="物流编号:" prop="Code">
              <Input
                placeholder="请输入物流公司编号"
                v-model="params.Code"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="物流名称:" prop="Name">
              <Input
                placeholder="请输入物流公司名称"
                v-model="params.Name"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="状态:" prop="Status">
              <Select clearable v-model="params.Status" placeholder="状态">
                <Option value="0">启用</Option>
                <Option value="1">禁用</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Express_Edit'])"
            @click="handleOpenAdd"
          >
            新增
          </Button>
          <Button
            type="warning"
            class="ml2"
            v-if="$canAction(['Sys_Cache_Edit'])"
            @click="$importCache('AllExpress', $data)"
          >
            更新缓存
            <i>({{ cacheUpdatedTime }})</i>
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button class="ml2" @click="handleReset"> 重置 </Button>
        </Col>
      </Row>
    </Form>

    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      @on-sort-change="sortChange"
      :data="resObj.DataList"
      highlight-row
      size="small"
    >
      <template slot="Image" slot-scope="{ row }">
        <Tooltip placement="right">
          <Avatar
            :src="$setImagePrefix(row.Logo, 'product')"
            style="min-width: 31px; height: 31px; margin-top: 3px"
          />
          <div slot="content" style="height: 230px">
            <img
              :src="$setImagePrefix(row.Logo, 'product')"
              style="min-width: 230px; height: 230px; margin-top: 3px"
            />
          </div>
        </Tooltip>
      </template>
      <template slot="Status" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.Status === 10
              ? 'green'
              : row.Status === 20
              ? 'red'
              : row.Status === -99
              ? 'default'
              : 'default'
          "
        >
          {{ row.StatusCN }}
        </Tag>
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="success"
          v-if="$canAction(['Express_Edit'])"
          @click="handleOpenEdit(row)"
        >
          编辑
        </Button>
        <Button
          size="small"
          class="ml2"
          type="error"
          v-if="$canAction(['Express_Del'])"
          @click="handleOpenDelete(row)"
        >
          删除
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <edit-express-modal ref="editExpressModal" @on-save="onUpdated" />
  </Layout>
</template>
<script>
import api from "@/api";
import layout from "@/components/layout/index.vue";
import editExpressModal from "./components/express-edit.vue";
export default {
  name: "ExpressManage",
  data() {
    return {
      tableLoading: false,
      cacheUpdatedTime: "",
      params: {
        Code: "",
        Status: "",
        Name: "",
        Page: 1,
        PageSize: 40,
        SortName: "Code",
        SortOrder: "Desc",
      },
      tableHeight: 0,
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      columns: [
        {
          title: "Logo",
          key: "Logo",
          minWidth: 85,
          align: "center",
          slot: "Image",
        },
        {
          title: "公司编号",
          key: "Code",
          minWidth: 90,
          ellipsis: true,
          align: "center",
          sortable: "custom",
        },
        {
          title: "公司名称",
          key: "Name",
          minWidth: 85,
          ellipsis: true,
          align: "center",
        },
        {
          title: "公司官网",
          key: "Website",
          minWidth: 200,
          ellipsis: true,
          align: "left",
        },
        {
          title: "联系座机",
          key: "ContractTel",
          minWidth: 116,
          ellipsis: true,
          align: "center",
        },
        {
          title: "状态",
          key: "Status",
          slot: "Status",
          align: "center",
          minWidth: 100,
          sortable: "custom",
        },
        {
          title: "创建时间",
          key: "CreateDate",
          minWidth: 135,
          ellipsis: true,
          align: "center",
          sortable: "custom",
        },
      ],
    };
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.LoadList();
    this.$getCacheUpdatedTime("AllExpress", this);
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.LoadList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.LoadList();
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.LoadList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  methods: {
    async LoadList() {
      this.tableLoading = true;
      try {
        const res = await api.GetExpressList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    onUpdated(row) {
      if (row.ID > 0) {
        const index = this.resObj.DataList.findIndex(
          (item) => item.ID === row.ID
        );
        if (index >= 0) {
          this.resObj.DataList.splice(index, 1, row);
        } else {
          this.resObj.DataList.push(row);
        }
      }
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.LoadList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.LoadList();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.LoadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.LoadList();
    },
    handleSearch() {
      this.params.Page = 1;
      this.LoadList();
    },
    async handleOpenEdit(row) {
      try {
        this.$refs.editExpressModal.$refs.form.resetFields();
        this.$refs.editExpressModal.isShow = true;
        this.$refs.editExpressModal.obj = JSON.parse(JSON.stringify(row));
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        // TODO: 记录错误日志
      }
    },
    async handleOpenAdd() {
      try {
        this.$refs.editExpressModal.$refs.form.resetFields();
        this.$refs.editExpressModal.isShow = true;
        this.$refs.editExpressModal.obj = {};
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        // TODO: 记录错误日志
      }
    },
    handleOpenDelete(row) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        closable: true,
        loading: true,
        content: "您确定要删除【" + row.Name + "】吗？",
        onOk: function () {
          _this.Delete(row.ID);
        },
        onCancel: () => {
          _this.$Modal.remove();
        },
      });
    },
    async Delete(id) {
      try {
        var res = await api.DeleteExpress({
          id: id,
        });
        if (res.Status === 100) {
          // this.delayLoadList()
          this.$Message.success("删除成功");
          this.$Modal.remove();
          const index = this.resObj.DataList.findIndex(
            (item) => item.ID === id
          );
          if (index >= 0) {
            this.resObj.DataList.splice(index, 1);
          }
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Modal.remove();
      }
    },
  },
  components: { editExpressModal, layout },
};
</script>
